/* eslint-disable import/prefer-default-export, react/prop-types */
import React from 'react';
import 'firebase/auth'
import AuthProvider from '../../src/context/auth';

import TopLayout from './TopLayout';

export const wrapRootElement = ({ element }) => {
  return<TopLayout> <AuthProvider>{element}</AuthProvider></TopLayout>;
};
